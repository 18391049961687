import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TextEditor from '../Helpers/TextEditor'
import FooterSettings from './footerSettings'
import ThemeSettings from './themeSettings'
import FieldGroup from '../fieldGroup'
import { Grid, Paper, Typography, Divider, InputAdornment, TextField, MenuItem, FormControlLabel, Checkbox } from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'
import Alert from '@mui/material/Alert'
import UploadImageWidget from '../Shared/UploadImageWidget'
import IconTextField from '../Shared/IconTextField'

const styles = {
  helperText: {
    fontWeight: '400',
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.6)',
    marginLeft: '14px',
    display: 'block'
  },
  headingContainer: {
    padding: '1%',
    backgroundColor: '#f5f5f5'
  }
}

const EditStore = (props) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>{props.store.updating && (<Alert severity="info">Updating settings...</Alert>)}</Grid>
    <Grid container item xs={12}>
      <Paper sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={12} sx={styles.headingContainer}>
            <h4>General Settings</h4>
          </Grid>
          <Divider width="100%" />
          <Grid container item sx={{padding: '1%'}}>
            <Grid container item direction="column" xs={12} md={8} sx={{paddingRight: '2%'}}>
              <Grid item>
                <FieldGroup
                  id="title_tag"
                  label="Title tag"
                  name="title_tag"
                  value={props.store.title_tag}
                  placeholder="Title tag text"
                  onChange={props.onChange} />
              </Grid>
              <Grid item>
                <FieldGroup
                  id="seo_description"
                  label="SEO Description"
                  name="seo_description"
                  value={props.store.seo_description}
                  multiline
                  onChange={props.onChange}
                  helperText="Describe what this store is about, this text is used for SEO tags on public website." />
              </Grid>
              <Grid item>
                <b>Footer text</b>
                <TextEditor
                  value={props.store.footer_text}
                  onChange={props.onFooterChange} />
                <Typography variant='p' sx={styles.helperText}>This text appears on homepage before footer section.</Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} direction="column" xs={12} md={4}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <UploadImageWidget
                    object={props.store}
                    onChange={props.onChange}
                    deleteImage={props.removeLogo}
                    title="Logo"
                    field="logo"
                    size="medium" />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <UploadImageWidget
                    object={props.store}
                    onChange={props.onChange}
                    deleteImage={props.removeFavicon}
                    title="Favicon"
                    field="favicon"
                    description="Recommended size is 32x32px. In general square icons work best for favicon."
                    size="medium" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid container item xs={12}>
      <Paper sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={12} sx={styles.headingContainer}>
            <h4>Domains - Customise store URL or add you own custom domain.</h4>
          </Grid>
          <Divider width="100%" />
          <Grid container sx={{padding: '1%'}}>
            <Grid container item spacing={2} direction="column" xs={12} md={6} sx={{paddingRight: '2%'}}>
              <Grid item>
                <TextField
                  id="store_url"
                  label="Store URL"
                  name="store_url"
                  value={props.store.store_url}
                  placeholder="URL"
                  onBlur={props.validateStoreUrl} 
                  onChange={props.onChange}
                  helperText={props.store.store_url ? (
                    <a href={`http://${process.env.REACT_APP_STORE_HOST}/${props.store.store_url}`} target="blank">{`${process.env.REACT_APP_STORE_HOST}/${props.store.store_url}`}</a>
                  ) : (null)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        { props.urlValid && (<CheckIcon />)}
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
              <Grid item>
                <TextField
                  id="primary_domain"
                  label="Custom Domain"
                  name="primary_domain"
                  value={props.store.primary_domain}
                  placeholder="yourdomain.com"
                  onChange={props.onChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        { props.urlValid && (<CheckIcon />)}
                      </InputAdornment>
                    ),
                  }} />
                {props.store.primary_domain ? (
                  <span>
                    Visit Store: {' '} 
                    <a href={`http://${props.store.primary_domain}`} target="blank">{`${props.store.primary_domain}`}</a>
                  </span>
                ) : (null)
                }
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={props.store.is_live} onChange={props.onChange} name="is_live" />}
                  label="Shop is Live!" />
                <Typography variant='p' sx={styles.helperText}>Would make the store accessible on the public domain</Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} direction="column" xs={12} md={6}>
              <Grid item>
                <TextField
                  select
                  name="authentication"
                  value={props.store.authentication}
                  label="Authentication"
                  helperText='Choose if login can be optional or mandatory to access the store. Remove login feature by selecting "No Authentication".'
                  placeholder="select"
                  onChange={props.onChange}>
                  <MenuItem value="0">{global.constants.labels.no_authentication}</MenuItem>
                  <MenuItem value="1">{global.constants.labels.login_require_mandatory}</MenuItem>
                  <MenuItem value="2">{global.constants.labels.login_require_optional}</MenuItem>
                </TextField>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={props.store.enable_account_registration} disabled={props.store.authentication === parseInt(global.constants.labels.no_authentication_code)} onChange={props.onChange} name="enable_account_registration" />}
                  label={global.constants.labels.user_registration} />
                <Typography variant='p' sx={styles.helperText}>{global.constants.response_messages.user_registration_help_block}</Typography>
              </Grid>
              {props.store.enable_account_registration && (<Grid item>
                <FormControlLabel
                  control={<Checkbox checked={props.store.auto_account_approval} disabled={!props.store.enable_account_registration} onChange={props.onChange} name="auto_account_approval" />}
                  label={global.constants.labels.auto_approve_registered_accounts} />
                <Typography variant='p' sx={styles.helperText}>{global.constants.response_messages.auto_approve_account_help_block}</Typography>
              </Grid>)}
              <Grid item>
                <TextField
                  select
                  fullWidth
                  name="password_protection"
                  label="Password Protection"
                  value={props.store.password_protection}
                  placeholder="select"
                  helperText={global.constants.response_messages.password_protection_label}
                  onChange={props.onChange}>
                  <MenuItem value="0">No</MenuItem>
                  <MenuItem value="1">Password</MenuItem>
                  <MenuItem value="2">IP-restriction</MenuItem>
                </TextField>
              </Grid>
              {(parseInt(props.store.password_protection) === parseInt(global.constants.labels.password_protected_code) || parseInt(props.store.password_protection) === 2) ? (
                <Grid item>
                  <TextField
                    fullWidth
                    type="text"
                    name="access_key"
                    value={props.store.access_key}
                    label={props.store.password_protection === 2 ? 'IP Address' : 'Password'}
                    pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$"
                    onChange={props.onChange} />
                  <Typography variant='p' sx={styles.helperText}>{global.constants.response_messages.password_protection_field_label}</Typography>
                </Grid>
              ) : (null)
              }
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid container item xs={12}>
      <Paper sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={12} sx={styles.headingContainer}>
            <h4>Contact details</h4>
          </Grid>
          <Divider width="100%" />
          <Grid container spacing={2} sx={{padding: '2%'}}>
            <Grid item xs={6}>
              <TextField
                label="Company Name"
                name="company_name"
                value={props.store.company_name || ''}
                onChange={props.onChange}
                fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Store Name(Brand Name)"
                name="name"
                value={props.store.name || ''}
                onChange={props.onChange}
                fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Address"
                name="address"
                value={props.store.address || ''}
                onChange={props.onChange}
                fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Organization Number"
                name="organisation_number"
                value={props.store.organisation_number || ''}
                onChange={props.onChange}
                fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Phone"
                name="phone"
                value={props.store.phone || ''}
                onChange={props.onChange}
                fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Contact Email"
                name="email"
                value={props.store.email || ''}
                onChange={props.onChange}
                fullWidth/>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid container item xs={12}>
      <Paper sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={12} sx={styles.headingContainer}>
            <h4>Payment Options</h4>
          </Grid>
          <Divider width="100%" />
          <Grid container direction="column" spacing={2}>
            <Grid container sx={{padding: '3%'}} spacing={2}>
              <Grid container item xs={12} md={6} spacing={0}>
                <Grid item xs={12}>
                  <label>Payment Options</label>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.credit_card} onChange={props.onChange} name="credit_card" />}
                    label="Credit Card" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.invoice} onChange={props.onChange} name="invoice" />}
                    label="Invoice" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.free} onChange={props.onChange} name="free" />}
                    label="Free (Send invoice to third party)" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.discount_payment === true} onChange={props.onChange} name="discount_payment" />}
                    label="Discount voucher" />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} spacing={2} >
                <Grid item xs={12}>
                  <TextField
                    label="Currency"
                    select
                    fullWidth
                    name="currencyCode"
                    value={props.store.currencyCode || ''}
                    onChange={props.onChange}>
                    {props.currencies.map(c => <MenuItem key={c.id} value={c.isoCode}>{c.name}</MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.prices_include_vat === true} onChange={props.onChange} name="prices_include_vat" />}
                    label="All prices include VAT" />
                  <Typography variant='p' sx={styles.helperText}>Taxes charged on shipping rates are included in the shipping price.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="vat"
                    label="VAT(%)"
                    value={`${props.store.vat}`}
                    placeholder=""
                    onChange={props.onChange}
                    fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.show_vat_on_listings === true} onChange={props.onChange} name="show_vat_on_listings" />}
                    label="Show VAT info. on product listings" />
                  <Typography variant='p' sx={styles.helperText}>It will display "Incl. VAT" or "Excl. VAT" next to product listing prices on store front.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid container item xs={12}>
      <Paper sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={12} sx={styles.headingContainer}>
            <h4>Shipping</h4>
          </Grid>
          <Divider width="100%" />
          <Grid container spacing={2} sx={{padding: '2%'}}>
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={!props.store.same_invoice_address} onChange={props.onChange} name="same_invoice_address" />}
                label="Require different billing address in checkout" />
              <Typography variant='p' sx={styles.helperText}>If checked, customer must enter/select a billing address on payment step during the checkout.</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Send copy of order to (Fulfilment service)"
                name="order_email"
                value={props.store.order_email || ''}
                helperText={`Multiple comma separated emails can also be added e.g. email1@${props.store.store_url}.com, email2@${props.store.store_url}.com`}
                placeholder="Order email"
                onChange={props.onChange}
                fullWidth />
            </Grid>
            <Grid item xs={12} md={7}>
              <IconTextField
                label="Frieght (per order)"
                name="freight_per_order"
                value={props.store.freight_per_order ?? 0}
                onChange={props.onChange}
                fullWidth={true}
                icon="kr"
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <IconTextField
                label="Frieght (per order) outside Sweden"
                name="freight_outside_sweden"
                value={props.store.freight_outside_sweden ?? 0}
                onChange={props.onChange}
                fullWidth={true}
                icon="kr"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    <ThemeSettings onChange={props.onChange} store={props.store} styles={styles} />
    <FooterSettings onChange={props.onChange} store={props.store} />
  </Grid>
)

EditStore.propTypes = {
  store: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validateStoreUrl: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showSecondaryColorPicker: PropTypes.bool,
  urlValid: PropTypes.bool,
  onColorFocus: PropTypes.func,
  onColorBlur: PropTypes.func,
  onPrimaryColorChange: PropTypes.func,
  onSecondaryColorChange: PropTypes.func,
  showPrimaryColorPicker: PropTypes.bool,
  onFooterChange: PropTypes.func,
  removeLogo: PropTypes.func.isRequired,
  removeFavicon: PropTypes.func.isRequired
}

export default EditStore
