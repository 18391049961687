import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TableCell, TableRow } from '@mui/material';

const FulfillmentCenter = ({ id, name, provider_type, status, email, index, deleteFulfillmentCenter }) => {

  const styles = {
    indexColumn: {
      width: '50px'
    }
  }

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row" sx={styles.indexColumn}><Link to={`/admin/fulfillment-centers/edit/${id}`}>{index}</Link></TableCell>
      <TableCell component="th" scope="row"><Link to={`/admin/fulfillment-centers/${id}/edit`}> {name}</Link></TableCell>
      <TableCell component="th" scope="row">{ email }</TableCell>
      <TableCell component="th" scope="row">{ provider_type }</TableCell>
      <TableCell component="th" scope="row">{ status }</TableCell>
      <TableCell align="left">
        <Link to={`/admin/fulfillment-centers/${id}/edit`}>
          <IconButton color="primary" size="small"><EditIcon /></IconButton>
        </Link>
        {provider_type !== 'third-party' && (
          <IconButton color="error" size="small" onClick={() => { if (window.confirm('Are you sure?')) deleteFulfillmentCenter() }}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

FulfillmentCenter.propTypes = {
  id: PropTypes.number.isRequired,
  deleteFulfillmentCenter: PropTypes.func.isRequired
}

export default FulfillmentCenter
