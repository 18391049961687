import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, Button } from '@mui/material'

const FileUpload = ({ name, onChange }) => {
  return (
    <FormGroup>
      <Button variant="outlined" component="label">
        Upload
        <input style={{ display: 'none' }} accept="image/*" multiple type="file" name={name} onChange={onChange} />
      </Button>
    </FormGroup>
  )
}

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default FileUpload
