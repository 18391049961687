import React, { useEffect, useState } from 'react'

import { OrderApi } from '../../api/orderApi'
import { useDispatch } from 'react-redux'
import { setSuccessNotification, setErrorNotification } from '../../actions/notificationAction'
import { Button, Grid, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import FieldGroup from '../fieldGroup'

const OrderTrackingDetails = ({ order }) => {
  const [orderTrackingEnabled, setOrderTrackingEnabled] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState('')
  const [orderTrackingData, setOrderTrackingData] = useState({
      trackingUrl: '',
      notes: '',
      lastEmailSentAt: null
  })

  const dispatch = useDispatch();

  const updateOrderTracking = async (sendEmail) => {
    if (orderTrackingData.trackingUrl === ''){
      setError('Please add tracking url to save information')
      dispatch(setErrorNotification({ message: 'Please add tracking url to save information' }))
    } else {
      const bodyData = { orderTrackingData: orderTrackingData, sendEmail: sendEmail}
      const response = await OrderApi.updateOrderTracking( order.id, bodyData)
      if (response.status === 200){
        setOrderTrackingData({ ...orderTrackingData, ...response.data.order_tracking })
        if (sendEmail){
          dispatch(setSuccessNotification({ message: global.constants.response_messages.order_tracking_email_success }))
        } else {
          dispatch(setSuccessNotification({ message: global.constants.response_messages.order_tracking_success }))
        }
        setIsEditing(false)
        setError('')
      }
    } 
  }

  const handleOrderTrackingUpdate = (target, value) => {
    setOrderTrackingData({...orderTrackingData, [target]: value})
  }

  const handleNewTrackingClick = () => {
    setOrderTrackingEnabled(true)
    setIsEditing(true)
  }

  useEffect(()=>{
    if (order?.order_tracking) {
      setOrderTrackingData({ ...orderTrackingData, ...order.order_tracking })
      setOrderTrackingEnabled(true)
    } else {
      setOrderTrackingEnabled(false)
    }
  },[order?.order_tracking])

  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        { orderTrackingEnabled && <h4>Order Tracking Details</h4>}
      </Grid>
      { (!isEditing && orderTrackingEnabled) &&
        <Grid item xs={2}>
          <Button sx={{float: 'right', borderColor: 'lightgrey'}} variant="outlined" onClick={()=>{setIsEditing(true)}}><EditIcon /></Button>
        </Grid>
      }
      {orderTrackingEnabled ?
        isEditing ? 
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <FieldGroup
                id="tracking-url"
                type="email"
                label="Tracking Url"
                name="tracking-url"
                value={orderTrackingData?.trackingUrl}
                onChange={(event)=>handleOrderTrackingUpdate('trackingUrl', event.target.value)}
                validationState={ error ? 'error' : null} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="tracking-notes"
                label="Notes"
                value={orderTrackingData?.notes}
                name="tacking-notes"
                onChange={(event)=>handleOrderTrackingUpdate('notes', event.target.value)}
                multiline
                fullWidth
                variant='outlined' />
              { orderTrackingData.lastEmailSentAt && <p>Last Email sent at {orderTrackingData.lastEmailSentAt}</p>}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                color="primary"
                onClick={()=>updateOrderTracking(false)}>
                  Save
              </Button>
            </Grid>
            { orderTrackingData.id &&
              <Grid item xs={12}>
                <Button
                  variant='contained'
                  color="primary"
                  onClick={()=>updateOrderTracking(true)}>
                    Save and Send Email
                </Button>
              </Grid>
            }
          </Grid>
        :
        <Grid container item spacing={3}>
          <Grid item>
            <div><strong> Tracking Url : </strong><a href={orderTrackingData?.trackingUrl}>{orderTrackingData?.trackingUrl}</a></div>
            <div><strong> Notes : </strong>{orderTrackingData?.notes}</div>
            { orderTrackingData.lastEmailSentAt && <p>Last Email sent at {orderTrackingData.lastEmailSentAt}</p>}
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant='contained'
              onClick={()=>updateOrderTracking(true)} >
                Send email to customer
            </Button>
          </Grid>
        </Grid>
        :
        <Grid item>
          <Button
            sx={{borderColor: 'lightgrey'}}
            color="primary"
            variant='contained'
            onClick={handleNewTrackingClick} >
              Add Tracking information
          </Button>
        </Grid>
      }
    </Grid>
  )

}

export default OrderTrackingDetails;
