import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import EditStoreContainer from '../containers/Store/editStoreContainer'
import StoreContainer from '../containers/Store/storeContainer'

const Stores = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={StoreContainer} />
    <Route path={`${match.path}/:id/edit`} component={EditStoreContainer} />
  </Switch>
)

Stores.propTypes = {  
  match: PropTypes.object,
}

export default Stores