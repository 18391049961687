import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Grid, Button, Autocomplete, TextField } from '@mui/material';
import { CSVLink } from "react-csv";
import { ProductApi } from "../../api/productApi";
import { setErrorNotification } from '../../actions/notificationAction'
import { getSerialNumber } from '../../utils/sharedFunctions'
import { productSorting } from "../../config/sortingLabels";
import { productCSVHeaders } from "../../config/csvHeaders";
import useListingActions from '../../hooks/useListingActions'
import { actionBtn } from '../styles'
import { Product, ListingPage, ListTableActions } from '../../shared-components';
import { StoreApi } from '../../api/storeApi'

const styles = {
  actionBtn: {
    textTransform: 'none',
    marginTop: '2%',
    float: 'right'
  },
  autocomplete: {
    width: 200,
  }
}

const ProductContainer = () => {
  const [dataForExport, setDataForExport] = useState([])
  const csvButtonRef = useRef(null)
  const dispatch = useDispatch()
  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState(null)
  const history = useHistory()

  const handleExtraParameters = () => {
    if (selectedStore) {
      return { store_id: selectedStore.id }
    } else {
      return {}
    }
  }

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
    fetchData,
  } = useListingActions('products', ProductApi.getProducts, 'latest', handleExtraParameters );

  let getProducts = async (query='',sort_by='Name ascending', exportRows=false ) => {
    const response = await ProductApi.getProducts({ page: page, per_page: perPage, q: query, sort_by: sort_by, export_records: exportRows, store_id: selectedStore.id })
    return response.data.data
  }

  const newProduct = () => history.push('/admin/products/new')

  const deleteProduct = product => {
    ProductApi.removeProduct(product).then(res => {
      if (res.status === 204) {
        setData(data.filter(prod => prod.id !== product.id))
        dispatch(setErrorNotification({ message: global.constants.response_messages.products.deleted }))
      }
    })
  }

  const fetchAllStores = async () => {
    const response = await StoreApi.getStores({ page: -1 })
    setStores(
      response.data.data.map((store) => {
        return { id: store.id, name: store.name }
      })
    )
  }

  const handleStoreChange = (e, value) => {
    setSelectedStore(value)
    fetchData({ page: 1, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected, ...value && {store_id: value.id} }, true)
  }

  useEffect(() => {
    fetchAllStores()
  }, [])

  const exportCSV = async () => {
    const dataToExport = await getProducts(searchTerm, sortOptionSelected, true )
    dataToExport?.map((product)=>{
      product.variants = product.variants.map(variant => variant.name).join(', ')
      product.category = product.category_slug
      product.createdAt = new Date(product.createdAt).toLocaleString('en-US')
    })
    if (dataToExport.length > 0) {
      setDataForExport(dataToExport)
      csvButtonRef.current.link.click()
    }
  }

  const tableActions = (
    <ListTableActions
      searchTerm={searchTerm}
      searchText='Search Products'
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={productSorting}
    />
  )

  const handleReorder = (productId) => { history.push(`/admin/products/reorder`);};

  const buttons = (
    <Grid container justifyContent="flex-end" spacing={1}>
    <Grid item>
      <Autocomplete
        disablePortal
        id='stores'
        size='small'
        options={stores}
        getOptionLabel={(store) => store.name}
        onChange={handleStoreChange}
        sx={styles.autocomplete}
        renderInput={(params) => (<TextField {...params} label='Search Store' />
        )}
      />
    </Grid>
    <Grid item>
    <Button variant="outlined" sx={actionBtn}
              onClick={handleReorder}>Reorder Products</Button>  
    <CSVLink
        data={dataForExport}
        headers={productCSVHeaders}
        filename={`products-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
        className='hidden'
        ref={csvButtonRef}
        target='_blank'/>
      <Button variant="outlined" sx={actionBtn}
              onClick={exportCSV}>{global.constants.labels.exportToCsv}</Button>
    </Grid>
  </Grid>
  )

  const tableContent = productTableContent(deleteProduct, data, page, perPage)

  return (
    <ListingPage
      title="Products"
      actions={buttons}
      tableContent={tableContent} tableActions={tableActions} count = {count} page= {page}  perPage = {perPage} handlerForSearchingAndSortingChange ={handlerForSearchingAndSortingChange}  />
  )
}

const productTableContent = (deleteProduct, products=[], page_no, per_page) => {
  const history = useHistory()
  const handleEdit = (productId) => { history.push(`/admin/products/edit/${productId}`);};
  const productBody = products.map( (product,index) => (
    <Product product={product} deleteProduct={() => deleteProduct(product)} index={getSerialNumber(page_no, per_page, index)} handleEdit={handleEdit} />
  ))
  return { productBody: productBody, productHeading: ['Id', '', 'Name', 'Article No.', 'Category', 'Public', ''] }
}

export default ProductContainer
