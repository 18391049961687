import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import ListingPage from "../../components/Shared/ListingPage";
import FulfillmentCenter from "../../components/FulfillmentCenter/fulfillmentCenter";
import { ListTable } from "../../components/Shared/ListingTable";
import { getSerialNumber } from "../../utils/sharedFunctions";
import ListTableActions from "../../components/Shared/ListingTable/listTableActions";
import { FulfillmentCenterAPI } from "../../api/fulfillmentCenterApi";
import { collectionSorting } from "../../config/sortingLabels";
import useListingActions from "../../hooks/useListingActions";
import { actionBtn } from '../styles'

const FulfillmentCenterContainer = () => {
  const history = useHistory();

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('fulfillmentCenters', FulfillmentCenterAPI.getFulfillmentCenters );

  const deleteSingleFulfillmentCenter = async (fulfillmentCenter) => {
    await FulfillmentCenterAPI.removeFulfillmentCenter(fulfillmentCenter)
    setData(data.filter(col => col.id !== fulfillmentCenter.id))
  }

  const fulfillmentCenterTableContent = () => {
    const fulfillmentCenterBody = data?.map((fulfillmentCenter, index) => (
      <FulfillmentCenter key={fulfillmentCenter.id} {...fulfillmentCenter} deleteFulfillmentCenter={ () => deleteSingleFulfillmentCenter(fulfillmentCenter)}
        index={getSerialNumber(page, perPage, index)}
      />
    ));
    return { body: fulfillmentCenterBody, heading: ['Id', 'Name', 'Email', 'Type', 'Status?	', 'Actions'] }
  }

  const tableActions = (
    <ListTableActions
      searchText='Search Fulfillment Centers'
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={collectionSorting}
    />
  )

  const actions = (
    <Button
      color="primary"
      variant="contained"
      sx={actionBtn}
      onClick={() => history.push('/admin/fulfillment-centers/new')}>
      Create Fulfillment Center
    </Button>
  )

  const tableContent = fulfillmentCenterTableContent()

  return (
    <ListingPage
      title= "Fulfillment Centers"
      actions={actions}
      table={
        <ListTable
          tableHeadings={tableContent.heading}
          tableContent={tableContent.body}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsControl={true} />
      }
    />
  )
}
  
export default FulfillmentCenterContainer