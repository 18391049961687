import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import SideBar from '../components/Header/SideBar'
import Dashboard from './Dashboard'
import Orders from './Orders'
import Stores from './Stores'
import Users from './Users'
import ErrorsContainer from '../containers/errorsContainer'
import Products from './Products'
import FulfillmentCenters from './FulfillmentCenters'

const Admin = ({ match, history }) => (
  <SideBar history={history}>
    <ErrorsContainer />
    <Switch>
      <Route exact path={`${match.path}/`} component={Dashboard} />
      <Route path={`${match.path}/dashboard`} component={Dashboard} />
      <Route path={`${match.path}/orders`} component={Orders} />
      <Route path={`${match.path}/stores`} component={Stores} />
      <Route path={`${match.path}/users`} component={Users} />
      <Route path={`${match.path}/products`} component={Products} />
      <Route path={`${match.path}/fulfillment-centers`} component={FulfillmentCenters} />
    </Switch>
  </SideBar>
)

Admin.propTypes = {  
  match: PropTypes.object,
  history: PropTypes.object
}

export default Admin
